<template>
    <div class="rotator-5-wrapper" :data-id="this.thisRandomId">
        <v-card
            v-if="displayReview && item"
            elevation="2"
            class="reviews-badge"
            :style="'background-color: ' + ((widget_data.widget.settings.background_type == 'Transparent') ? 'transparent' : widget_data.widget.settings.background_color) + '; color: ' + widget_data.widget.settings.text_color + ';  cursor: auto !important;'"
        >
            <div style="position: absolute; top: -35px; left: 50%; margin-left: -35px;">
                <img 
                    v-if="item.profile_picture"
                    :src="item.profile_picture"
                    :style="((widget_data.widget.settings.show_review_site == false) ? 'display: none;' : '')"
                    style="height: 70px; border-radius: 50%;"
                />

                <v-avatar color="blue" size="86" v-if="!item.profile_picture"><v-icon small color="white">mdi-account</v-icon></v-avatar>
            </div>
            
            <a href="javascript: void(0);" @click="stopRotator()" style="position: absolute; right: 10px; color: grey;"><i class="fa fa-times"></i></a>

            <div class="text-md text-gray-600" style="padding: 20px; padding-top: 40px; padding-bottom: 10px;" v-html='((item.review_text && item.review_text.toString().length > 80) ? item.review_text.substring(0, 80) + "..." : item.review_text)'></div>

            <div class="clearfix"></div>
            
            <div class="rg-rotator-5-content" style="padding-left: 20px; padding-bottom: 10px;">

                <div class="float-right pr-4">
                    <v-rating
                        :style="((widget_data.widget.settings.show_stars == false) ? 'display: none;' : '')"
                        :value="item.rating_value"
                        :color="widget_data.widget.settings.star_color"
                        dense
                        half-increments
                        readonly
                        size="17"
                    ></v-rating>
                </div>

                <h3 class="reviewer-title pt-1" v-if="widget_data.widget.settings.reviewer_name != 'Hide name'">
                    <strong style=" display: block; float: left;">
                        <span v-if="widget_data.widget.settings.reviewer_name == 'Show name'">{{ item.name }}</span>
                        <span v-if="widget_data.widget.settings.reviewer_name == 'Show first name'">{{ getFirstName(item.name) }}</span>

                        <img 
                            :src="require('@/assets/images/review-services/www.' + getHostname(item) + '.png')"
                            :style="((widget_data.widget.settings.show_review_site == false) ? 'display: none;' : 'display: inline-block;')"
                            style="width: 22px; margin-left: 5px;"
                        />
                    </strong>

                    <span v-if="widget_data.widget.settings.show_verified_badge" :style="'color: ' + widget_data.widget.settings.verified_badge_color + '; padding-left: 10px; font-size: 14px; display: block; float: left; font-weight: bold;'">
                        <i class="mdi mdi-check-circle-outline" :style="'color: ' + widget_data.widget.settings.verified_badge_color + '; font-size: 16px;'"></i>

                        Verified
                    </span>

                    <div style="clear: both;"></div>
                </h3>
            </div>

            <div style="clear: both;"></div>
        </v-card>

        <div v-if="widget_data.widget.subscription.stripe_id == 'starter'" class="reviews-footer" :style="'color: ' + widget_data.widget.settings.footer_text_color + '; text-align: center;'">
            Powered by <a href="https://reviewgrower.com/" target="review-grower-external" :style="'color: ' + widget_data.widget.settings.footer_link_color + ';'">Review Grower</a>
        </div>
    </div>
</template>

<script>

export default {
    props: ["widget_data", "fw"],

    components: {
    },

    data () {
      return {
        displayReview: false,
        item: null,
        thisRandomId: 1,
        destroyed: false
      }
    },

    methods: {
        showReview() {
            this.displayReview = true;

            if (this.widget_data.reviews.length > 0) {
                let item = this.widget_data.reviews[Math.floor(Math.random()*this.widget_data.reviews.length)];
                this.item = item;
            }

            let self = this;
            self.theTimeout = setTimeout(function() {
                self.nextReview(false);
            }, parseInt((self.widget_data.widget.settings.rotator_delay) ? self.widget_data.widget.settings.rotator_delay.replace('s', '') : 5) * 1000);
        },
        nextReview(onetwo = true) {
            if (this.destroyed)
                return;
            
            console.log('onetwo', onetwo)

            if (!onetwo) {
                document.querySelector('.rotator-5-wrapper[data-id="' + this.thisRandomId + '"]').style.display = 'none';

                parent.postMessage("rgHideWidget" + this.widget_data.widget.id,"*");  //  `*` on any domain  

                let self = this;
                self.theTimeout = setTimeout(function() {
                    self.nextReview(true);
                }, parseInt((self.widget_data.widget.settings.rotator_delay) ? self.widget_data.widget.settings.rotator_delay.replace('s', '') : 5) * 1000);
            }
            else {
                let self = this;
                self.theTimeout = setTimeout(function() {
                    self.$parent.fetchMoreReviews();
                    let item = self.widget_data.reviews[Math.floor(Math.random()*self.widget_data.reviews.length)];
                    self.item = item;
                    document.querySelector('.rotator-5-wrapper[data-id="' + self.thisRandomId + '"]').style.display = 'block';

                    parent.postMessage("rgShowWidget" + self.widget_data.widget.id,"*");  //  `*` on any domain  

                    self.theTimeout = setTimeout(function() {
                        self.nextReview(false);
                    }, parseInt((self.widget_data.widget.settings.rotator_delay) ? self.widget_data.widget.settings.rotator_delay.replace('s', '') : 5) * 1000);
                }, 1000);
            }
        },
        getHostname: function(item) {
            return new URL(item.listing.url).hostname.replace("www.", "");
        },
        stopRotator() {
            parent.postMessage("rgHideWidget" + this.widget_data.widget.id,"*");  //  `*` on any domain  
            this.displayReview = false;
        },
        getFirstName(name) {
            let names = name.split(' ');

            if (names.length == 1)
                return names[0];

            return names[0] + ' ' + names[1].substring(0, 1) + '.';
        }
    },

    mounted() {
        this.thisRandomId = Math.floor(Math.random() * 1000);
        this.showReview();
    },

    destroyed() {
        this.destroyed = true;
    }
}
</script>

<style type="text/css" scoped>
    .rotator-5-wrapper {
        width: auto;
        max-width: 410px;
        padding-bottom: 10px;
    }

.rg-rotator-5-content {
    min-width: 350px;
}

@media screen and (max-width: 700px) {
    .rg-rotator-5-content {
        min-width: auto;
    }
}
</style>