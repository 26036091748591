import Vue from 'vue'
import App from './App.vue'

import router from "./router";
import vuetify from '@/plugins/vuetify' // path to vuetify export
import VueMasonry from 'vue-masonry-css'

import '@mdi/font/css/materialdesignicons.min.css';

Vue.use(VueMasonry);

Vue.config.productionTip = false

// Plugins
// ...

// Sass file
require("./assets/styles/main.css");

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
