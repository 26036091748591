<template>
    <div class="embed-grid-wrapper pl-3 pr-3" :style="
        'background-color: ' + ((widget_data.widget.settings.background_type == 'Transparent') ? 'transparent' : widget_data.widget.settings.background_color) + ';' +
        'margin-left: -' + widget_data.widget.settings.padding_between_reviews + ';'
    ">
        <Header :widget_data="widget_data" />

        <div v-show="widget_data.widget.settings.show_leave_a_review_button == 1" class="pt-0 mb-5" style="width: 160px; margin: auto; margin-bottom: 20px;">
            <div class="border-1 border-blue text-primary text-xs text-bold px-4 py-2 rounded cursor-pointer" @click="openReviewFunnel()" style="margin-top: -5px;"><i class="mdi mdi-star mr-2" style="font-size: 14px;"> </i> WRITE A REVIEW</div>
        </div>

        <div class="clearfix"></div>

        <masonry
            :cols="getColumns"
            :gutter="0"
            >
            <div v-for="item in widget_data.reviews" :key="item.id">
                <Review :widget_data="widget_data" :item="item" />
            </div>
        </masonry>
    </div>
</template>

<script>
import Header from '@/views/panel/Business/Widgets/Render/Header.vue';
import Review from '@/views/panel/Business/Widgets/Render/Review.vue';

export default {
    props: ["widget_data", "fw"],

    components: {
        Header,
        Review
    },

    methods: {
        openReviewFunnel() {
            window.open(this.widget_data.review_funnel_link);
        }
    },

    computed: {
        getColumns() {
            if (this.widget_data.widget.settings.feed_columns == "Dynamic")
                return ((this.fw) ? {default: 4, 1500: 3, 1200: 2, 700: 1} : {default: 4, 1000: 3, 700: 2, 400: 1} );

            return this.widget_data.widget.settings.feed_columns;
        }
    }
}
</script>

<style type="text/css" scoped>
    .embed-grid-wrapper {
        width: 100%;
        padding-bottom: 10px;
    }

    .embed-grid-wrapper > div {
        justify-content: center;
    }
</style>