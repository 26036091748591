<template>
    <div class="embed-directory-wrapper pl-3 pr-3" :style="
        'background-color: ' + ((widget_data.widget.settings.background_type == 'Transparent') ? 'transparent' : widget_data.widget.settings.background_color) + ';' +
        'margin-left: -' + widget_data.widget.settings.padding_between_reviews + ';'
    ">
        <div class="rgwd-directory-top-badge">
            <i class="mdi mdi-star" :style="'color: ' + widget_data.widget.settings.star_color"></i>
            <div class="rating-nr">{{ widget_data.average_rating }}</div>
            <div class="">Out of 5</div>
        </div>
        <Header :widget_data="widget_data" />
        <HeaderAggregate :widget_data="widget_data" />

        <v-tabs class="ml-2 mb-2">
            <v-tab :ripple="false" @click="selectSource('all');">All</v-tab>
            <v-tab v-for="(item, index) in widget_data.widget.settings.listings" :key="index" :ripple="false" @click="selectSource(item.id);">{{ getListingName(item.url) }} ({{item.reviews_count }})</v-tab>
        </v-tabs>

        <masonry
            :cols="getColumns"
            :gutter="0"
            >
            <div v-for="item in widget_data.reviews" :key="item.id">
                <Review :widget_data="widget_data" :item="item" />
            </div>
        </masonry>

        <div class="pl-2 pt-3 text-sm text-center w-full" v-if="widget_data.reviews.length == 0">No reviews.</div>
    </div>
</template>

<script>
import Header from '@/views/panel/Business/Widgets/Render/Header.vue';
import HeaderAggregate from '@/views/panel/Business/Widgets/Render/HeaderAggregate.vue';
import Review from '@/views/panel/Business/Widgets/Render/Review.vue';
import reviewSitesNames from '@/config/review_sites/names_all';
import urlParser from 'url-parse'

export default {
    props: ["widget_data", "fw"],

    data () {
        return {
            reviewSitesNames: reviewSitesNames,
            displayReviews: []
        }
    },

    components: {
        Header,
        HeaderAggregate,
        Review
    },

    methods: {
        getHostname(url) {
            return urlParser(url).hostname.replace("www.", "")
        },
        getListingName(url) {
            let thisHostName = this.getHostname(url);
            let thisName = this.reviewSitesNames["www." + thisHostName.replace(".ca", ".com").replace("doctor.webmd", "webmd").replace("uk.trustpilot.com", "trustpilot.com").replace("ebay.co.uk", "ebay.com").replace("amazon.co.uk", "amazon.com").replace("glassdoor.co.uk", "glassdoor.com").replace("expedia.co.uk", "expedia.com")];

            return thisName;
        },

        selectSource(item) {
            this.$parent.selected_tab = item;
            this.$parent.getWidgetData();
        }
    },

    computed: {
        getColumns() {
            if (this.widget_data.widget.settings.feed_columns == "Dynamic")
                return ((this.fw) ? {default: 4, 1500: 3, 1200: 2, 700: 1} : {default: 4, 1000: 3, 700: 2, 400: 1} );

            return this.widget_data.widget.settings.feed_columns;
        }
    }
}
</script>

<style type="text/css" scoped>
    .embed-directory-wrapper {
        width: 100%;
        padding-bottom: 10px;
    }
    .reviews-list-title {
        font-weight: bold;
    }

    .rgwd-directory-top-badge {
        width: 200px; height: 200px; margin: auto; border-radius: 50%; border: solid 1px #CCCCCC; text-align: center; margin-top: 20px;
        margin-bottom: 20px;
    }

    .rgwd-directory-top-badge i {
        font-size: 40px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .rgwd-directory-top-badge .rating-nr {
        font-size: 70px;
        font-weight: bold;
        padding-top: 0px;
        padding-bottom: 0px;
        line-height: 92px;
    }
</style>